import React from "react";
import { Link } from "react-router-dom";
import SpotifyAlbumIDForm from "./SpotifyAlbumIDForm";
import { addTracks } from "./Database";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Track } from "./library_api/api";
import { spotifyAPI } from "./LibraryAPI";

interface Props {
  addTracks(tracks: Track[]): void;
}

function Import(props: Props) {
  const history = useHistory();

  function handleImportAllClick(e: React.MouseEvent) {
    e.preventDefault();
    spotifyAPI.importSpotify().then(response => {
      props.addTracks(response.data.tracks);
      history.push("/");
    });
  }

  return (
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <h2>Import</h2>
        <button className="btn btn-primary" onClick={handleImportAllClick}>
          Import Albums
        </button>
        <Link className="btn btn-secondary ml-2" to="/">
          Back
        </Link>
        <small id="passwordHelpBlock" className="form-text text-muted">
          Add all albums you favorited in Spotify since the last import. This can take a while.
        </small>
        <hr />
        <SpotifyAlbumIDForm />
      </div>
    </div>
  );
}

export default connect(null, { addTracks })(Import);
