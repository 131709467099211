import React from "react";

import "./Layout.css";

interface Props {
  children: React.ReactNode;
}

export function Layout(props: Props) {
  return <div className="container-fluid lb-container">{props.children}</div>;
}
