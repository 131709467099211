import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Release } from "./library_api/api";

import "./Footer.css";

function mapStateToProps(state: { releases: Release[] }) {
  const { releases } = state;
  return {
    releaseCount: releases.length
  };
}

interface Props {
  releaseCount: number;
}

class Footer extends React.Component<Props> {
  render() {
    const releaseBadgeClasses = ["badge", "badge-primary"];
    if (this.props.releaseCount === 0) {
      releaseBadgeClasses.push("invisible");
    }

    return (
      <nav className="fixed-bottom navbar-expand navbar-light bg-light py-1">
        <div className="container-fluid lb-navbar">
          <div className="row">
            <div className="col text-center">
              <Link className="nav-link" to="/">
                Library
              </Link>
            </div>
            <div className="col text-center">
              <Link className="nav-link" to="/releases">
                Releases{" "}
                <span className={releaseBadgeClasses.join(" ")}>{this.props.releaseCount}</span>
              </Link>
            </div>
            <div className="col text-center">
              <Link className="nav-link" to="/search">
                Search
              </Link>
            </div>
            <div className="col text-center">
              <Link className="nav-link" to="/import">
                Import
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default connect(mapStateToProps)(Footer);
