import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
// import logo from './logo.svg';
import "./App.css";
import Footer from "./Footer";
import Content from "./Content";
import LandingPage from "./LandingPage";
import "./handlebars";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AlbumEdit from "./AlbumEdit";
import { addReleases, addTracks } from "./Database";
import Import from "./Import";
import { connect } from "react-redux";
import Releases from "./Releases";
import { releasesAPI, setAccessToken, tracksAPI } from "./LibraryAPI";
import { Release, Track } from "./library_api";
import Search from "./Search";
import { Layout } from "./Layout";

interface Props {
  addReleases(releases: Release[]): void;
  addTracks(tracks: Track[]): void;
}

interface State {
  isLoading: boolean;
  isLoggedIn: boolean;
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      isLoggedIn: false
    };

    this.onNotAuthorized = this.onNotAuthorized.bind(this);
  }

  componentDidMount() {
    try {
      const accessToken = localStorage.getItem("lbt");
      if (accessToken === null) {
        this.setState({
          isLoading: false
        });
        return;
      }

      setAccessToken(accessToken);
      this.setState({
        isLoggedIn: true
      });

      tracksAPI
        .listTracks()
        .then(resp => {
          const trackList = resp.data;
          this.props.addTracks(trackList.tracks);
          this.setState({
            isLoading: false
          });
        })
        .catch(e => {
          console.log(e);
        });

      releasesAPI
        .listReleases()
        .then(resp => {
          const releaseList = resp.data;
          this.props.addReleases(releaseList.releases);
        })
        .catch(e => {
          console.log(e);
        });
    } catch (e) {
      return;
    }
  }

  onNotAuthorized() {
    this.setState({
      isLoggedIn: false
    });
  }

  render() {
    if (this.state.isLoading) {
      return <div className="App h-100"></div>;
    }

    if (!this.state.isLoggedIn) {
      return (
        <div className="App h-100">
          <LandingPage />
        </div>
      );
    }

    return (
      <Router>
        <Switch>
          <Route path="/edit/:artist/:album">
            <Layout>
              <AlbumEdit />
            </Layout>
            <Footer />
          </Route>
          <Route path="/import">
            <Layout>
              <Import />
            </Layout>
            <Footer />
          </Route>
          <Route path="/releases">
            <Layout>
              <Releases />
            </Layout>
            <Footer />
          </Route>
          <Route path="/search">
            <Layout>
              <Search />
            </Layout>
            <Footer />
          </Route>
          <Route exact path="/">
            <Layout>
              <Content />
            </Layout>
            <Footer />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default connect(null, { addReleases, addTracks })(App);
