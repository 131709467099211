import React, { useEffect, useRef, useState } from "react";
import Fuse from "fuse.js";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { setFilters, Filter } from "./Database";

import "./Search.css";
import { Track } from "./library_api";

interface SearchEntry {
  album: string;
  artist: string;
  tag: string;
}

interface State {
  tracks: Track[];
}

function mapStateToProps(state: State): { data: SearchEntry[] } {
  const { tracks } = state;
  const unique: string[] = [];
  const data = tracks
    .filter(elm => {
      const key = elm.artist + "" + elm.album;
      if (unique.includes(key)) {
        return false;
      }

      unique.push(key);
      return true;
    })
    .map(track => {
      return { artist: track.artist, album: track.album, tag: track.tags[0] };
    });

  return {
    data: data
  };
}

interface Props {
  data: SearchEntry[];
  setFilters(filters: Filter[]): void;
}

function Search(props: Props) {
  const searchInput = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [fuse, setFuse] = useState<Fuse<SearchEntry, Fuse.IFuseOptions<SearchEntry>>>();
  const [result, setResult] = useState<SearchEntry[]>([]);
  useEffect(() => {
    setFuse(new Fuse(props.data, { keys: ["artist", "album"] }));
  }, [props.data]);

  useEffect(() => {
    if (!fuse) {
      return;
    }

    const searchResults = fuse
      .search(search)
      .slice(0, 10)
      .map(entry => entry.item);
    setResult(searchResults);
  }, [fuse, search]);

  useEffect(() => {
    searchInput?.current?.focus();
  }, []);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  function onSelect(item: SearchEntry) {
    props.setFilters([
      { key: "tags", value: item.tag },
      { key: "artist", value: item.artist },
      { key: "album", value: item.album }
    ]);
    setSearch("");
    setResult([]);
    history.push("/");
  }

  const results = result.map((r, idx) => {
    return (
      <div key={idx} className="py-1" onClick={() => onSelect(r)}>
        {r.artist} - {r.album}
      </div>
    );
  });

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="lb-search">
            <form className="form-inline">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search your library..."
                  aria-label="Search"
                  value={search}
                  onChange={onChange}
                  ref={searchInput}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="lb-search-result">{results}</div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps, { setFilters })(Search);
