import React from "react";
import { connect } from "react-redux";

import "./Content.css";
import AlbumDetail from "./AlbumDetail";
import { Filter, query } from "./Database";
import ContentColumn from "./ContentColumn";
import { Track } from "./library_api";

const tables = [
  {
    header: "Genre",
    key: "tags",
    template: "{{tag}}"
  },
  {
    header: "Artist",
    key: "artist",
    template: "{{artist}}"
  },
  {
    header: "Album",
    key: "album",
    template: "{{album}}"
  }
];

interface State {
  filters: Filter[];
  tracks: Track[];
}

function mapStateToProps(state: State) {
  const { filters, tracks } = state;
  return {
    filters: filters,
    tracks: tracks
  };
}

interface Props {
  filters: Filter[];
  tracks: Track[];
}

class Content extends React.Component<Props> {
  render() {
    const tableColumns = tables.map((t, idx) => {
      return (
        <div className="table-responsive col-sm" key={t.key}>
          {this.renderTable(t.header, t.key, idx, t.template)}
        </div>
      );
    });

    return (
      <div className="container-fluid">
        <div className="row no-gutters">{tableColumns}</div>
        <div className="row">
          <AlbumDetail />
        </div>
      </div>
    );
  }

  renderTable(header: string, key: string, filterCount: number, template: string) {
    if (filterCount > this.props.filters.length) {
      return (
        <table className="table table-sm music-table mb-0">
          <thead>
            <tr>
              <th>{header}</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      );
    }

    const filters = this.props.filters.slice(0, filterCount);
    let highlight = "";
    if (this.props.filters.length >= filterCount + 1) {
      highlight = this.props.filters[filterCount].value;
    }

    const tracks = query(filters, this.props.tracks);
    return (
      <ContentColumn
        filterPosition={filterCount}
        header={header}
        highlight={highlight}
        sectionKey={key}
        template={template}
        tracks={tracks}
      />
    );
  }
}

export default connect(mapStateToProps)(Content);
