import { Configuration } from "./library_api/configuration";
import { ReleasesApi, SpotifyApi, TracksApi } from "./library_api/api";

const configuration = new Configuration();

export function setAccessToken(at: string) {
  configuration.accessToken = at;
}

export const releasesAPI = new ReleasesApi(configuration, "");
export const tracksAPI = new TracksApi(configuration, "");
export const spotifyAPI = new SpotifyApi(configuration, "");
