import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { onlyUnique } from "./array";
import { getTemplate } from "./handlebars";
import { connect } from "react-redux";
import { Filter, setFiltersTo } from "./Database";
import { Track } from "./library_api";

function handleSpotifyClick(e: React.MouseEvent) {
  e.stopPropagation();
}

function sortIgnoreCase(a: string, b: string) {
  return a
    .trim()
    .toUpperCase()
    .localeCompare(b.trim().toUpperCase());
}

interface TableRowProps {
  className?: string;
  ref?: React.RefObject<HTMLTableRowElement> | null;
}

interface Props {
  filterPosition: number;
  header: string;
  highlight: string;
  sectionKey: string;
  template: string;
  tracks: Track[];
  setFiltersTo(position: number, filter: Filter): void;
}

function ContentColumn(props: Props) {
  function setFilter(key: string, value: string, position: number) {
    props.setFiltersTo(position, { key: key, value: value });
  }

  let ref = useRef<HTMLTableRowElement>(null);
  // nameToTrack
  const nToT: Record<string, Track> = {};
  const template = getTemplate(props.template);
  const names = props.tracks
    .map(track => {
      if (props.sectionKey === "tags") {
        const tags = track.tags;
        tags.forEach(tag => {
          nToT[tag] = track;
        });
        return track.tags;
      }

      const display = template(track);
      nToT[display] = track;
      return display;
    })
    .flat();

  const rows = names
    .filter(onlyUnique)
    .sort(sortIgnoreCase)
    .map(s => {
      const key = props.sectionKey;
      const track = nToT[s];
      let editLink;
      let albumLink;
      if (key === "album") {
        const artistEnc = encodeURIComponent(track.artist);
        const albumEnc = encodeURIComponent(s);
        editLink = (
          <Link
            className="btn btn-link music-table-btn-edit"
            to={"/edit/" + artistEnc + "/" + albumEnc}
          >
            <i className="bx bx-edit"></i>
          </Link>
        );

        albumLink = (
          <a
            className="btn btn-link music-table-btn-spotify"
            href={`spotify:album:${track["spotifyAlbumID"]}`}
            onClick={handleSpotifyClick}
          >
            <i className="bx bxl-spotify"></i>
          </a>
        );
      }

      const trProps: TableRowProps = {};
      if (props.highlight === s) {
        trProps.className = "table-primary";
        trProps.ref = ref;

        setTimeout(function() {
          ref?.current?.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "nearest"
          });
        }, 200);
      }

      return (
        <tr key={s} {...trProps}>
          <td onClick={() => setFilter(key, s, props.filterPosition)}>
            <div className="row">
              <div className="col-10 music-table-value">{s}</div>
              <div className="col-2">
                {editLink}
                {albumLink}
              </div>
            </div>
          </td>
        </tr>
      );
    });

  return (
    <table className="table table-sm music-table mb-0">
      <thead>
        <tr>
          <th>{props.header}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
}

export default connect(null, { setFiltersTo })(ContentColumn);
