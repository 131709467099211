import React from "react";

class LandingPage extends React.Component {
  render() {
    return (
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">welcome to the library</h5>
              <p>
                <a className="btn btn-primary" href="/auth/login">
                  Sign in with Spotify
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
