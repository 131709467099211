import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { deleteTracks, query, setFilters, updateTrackTags, Filter } from "./Database";
import TagsInput from "react-tagsinput";
import { Track } from "./library_api";
import { tracksAPI } from "./LibraryAPI";

import "./AlbumEdit.css";

let allTracks: Track[] = [];

interface State {
  tracks: Track[];
}

function mapStateToProps(state: State) {
  const { tracks } = state;
  if (allTracks.length === 0) {
    allTracks = tracks;
  }

  return {
    tracks: allTracks
  };
}

interface Params {
  artist: string;
  album: string;
}

interface Props {
  deleteTracks(ids: number[]): void;
  setFilters(filters: Filter[]): void;
  tracks: Track[];
  updateTrackTags(id: number, tags: string[]): void;
}

function AlbumEdit(props: Props) {
  const { artist, album } = useParams<Params>();
  const history = useHistory();
  const [tracks, setTracks] = useState<Track[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    const tracks = query(
      [
        { key: "artist", value: decodeURIComponent(artist) },
        { key: "album", value: decodeURIComponent(album) }
      ],
      props.tracks
    );
    setTracks(tracks);
    setTags(tracks[0].tags);
  }, [artist, album, props.tracks]);

  function handleBack() {
    history.push("/");
  }

  function handleRemove() {
    tracksAPI
      .deleteTracks({ tracks: tracks })
      .then(() => {
        const ids = tracks.map(t => {
          return t.id;
        });
        props.deleteTracks(ids);
        allTracks = [];
        history.push("/");
      })
      .catch(error => console.log(error));
  }

  function handleSave() {
    tracks.forEach(t => (t["tags"] = tags));
    tracksAPI
      .updateTracks({ tracks: tracks })
      .then(updatedTrackList => {
        updatedTrackList.data.tracks.forEach(t => {
          props.updateTrackTags(t.id, t.tags);
        });
        props.setFilters([
          { key: "tags", value: updatedTrackList.data.tracks[0].tags[0] },
          { key: "artist", value: updatedTrackList.data.tracks[0].artist },
          { key: "album", value: updatedTrackList.data.tracks[0].album }
        ]);
        allTracks = [];
        history.push("/");
      })
      .catch(error => console.log(error));
  }

  function handleTagsChange(tags: string[]) {
    setTags(tags);
  }

  return (
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <h2>Edit Album</h2>
        <form>
          <div className="form-group">
            <label htmlFor="artistEditName">Artist</label>
            <input
              type="text"
              className="form-control"
              id="artistEditName"
              aria-describedby="artistEditName"
              readOnly
              value={decodeURIComponent(artist)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="albumEditName">Album</label>
            <input
              type="text"
              className="form-control"
              id="albumEditName"
              aria-describedby="albumEditName"
              readOnly
              value={decodeURIComponent(album)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="albumEditGenre">Genres</label>
            <TagsInput
              className="form-control"
              value={tags}
              onChange={handleTagsChange}
              inputProps={{
                className: "react-tagsinput-input",
                placeholder: "Add a genre"
              }}
              tagProps={{
                className: "react-tagsinput-tag rounded",
                classNameRemove: "react-tagsinput-remove"
              }}
            />
          </div>
          <button type="button" className="btn btn-primary mr-1" onClick={handleSave}>
            Save changes
          </button>
          <button type="button" className="btn btn-secondary mr-1" onClick={handleBack}>
            Back
          </button>
          <button type="button" className="btn btn-danger float-right" onClick={handleRemove}>
            Remove from library
          </button>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, { deleteTracks, setFilters, updateTrackTags })(AlbumEdit);
