import React, { useState } from "react";

import "./Rating.css";

interface Props {
  max: number;
  setRating(rating: number): void;
  value: number;
}

export default function Rating(props: Props) {
  const [value, setValue] = useState(props.value);
  const stars = [];
  for (let i = 1; i <= value; i++) {
    const handleClick = function() {
      setValue(i);
      props.setRating(i);
    };

    stars.push(
      <span key={i} onClick={handleClick}>
        ★
      </span>
    );
  }

  for (let i = value + 1; i <= props.max; i++) {
    const handleClick = function() {
      setValue(i);
      props.setRating(i);
    };
    stars.push(
      <span key={i} onClick={handleClick}>
        ☆
      </span>
    );
  }

  return <div className="lb-rating">{stars.reverse()}</div>;
}
