import React, { useState } from "react";
import moment from "moment";

import "./AlbumDetail.css";

import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Rating from "./Rating";
import { connect } from "react-redux";
import { Filter, query, updateTrackRating } from "./Database";
import { Track } from "./library_api";
import { tracksAPI } from "./LibraryAPI";

interface Sorter {
  key: string;
  order: string;
}

const defaultSorters: Sorter[] = [
  {
    key: "artist",
    order: "asc"
  },
  {
    key: "album",
    order: "asc"
  },
  {
    key: "number",
    order: "asc"
  }
];

interface State {
  filters: Filter[];
  tracks: Track[];
}

function mapStateToProps(state: State) {
  const { filters, tracks } = state;
  return {
    filters: filters,
    tracks: tracks
  };
}

interface Props {
  filters: Filter[];
  tracks: Track[];
  updateTrackRating(id: number, rating: number): void;
}

function AlbumDetail(props: Props) {
  let tracks: Track[] = [];
  if (props.filters.length > 0) {
    tracks = query(props.filters, props.tracks);
  }

  const [sorters, setSorters] = useState(defaultSorters);
  tracks.sort((first, second) => {
    for (const sorter of sorters) {
      const valA =
        sorter.order === "asc"
          ? first[sorter.key as keyof Track]
          : second[sorter.key as keyof Track];
      const valB =
        sorter.order === "asc"
          ? second[sorter.key as keyof Track]
          : first[sorter.key as keyof Track];

      if (valA !== valB) {
        if (Number.isInteger(valA)) {
          return (valA as number) - (valB as number);
        }

        return (valA as string).localeCompare(valB as string);
      }
    }

    return 0;
  });

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const track = tracks[index];
    const setRating = function(value: number) {
      track.rating = value;
      tracksAPI.updateTracks({ tracks: [track] }).then(() => {
        props.updateTrackRating(track.id, track.rating);
      });
    };

    const releaseDate = moment(track.releaseDate).format("YYYY-MM-DD");
    const addedAt = moment(track.addedAt).format("YYYY-MM-DD");
    return (
      <div className="lb-album-detail-table-row" key={track.id} style={style}>
        <div className="lb-album-detail-table-cell">{track.artist}</div>
        <div className="lb-album-detail-table-cell">{String(track.number).padStart(2, "0")}</div>
        <div className="lb-album-detail-table-cell">{track.name}</div>
        <div className="lb-album-detail-table-cell">{track.album}</div>
        <div className="lb-album-detail-table-cell">{releaseDate}</div>
        <div className="lb-album-detail-table-cell">{addedAt}</div>
        <div className="lb-album-detail-table-cell">
          <Rating value={track.rating} max={5} setRating={setRating} />
        </div>
      </div>
    );
  };

  function createSortHandler(sortDef: Sorter[]): () => void {
    return () => {
      if (sortDef.length === 0) {
        return;
      }

      if (sorters[0].key === sortDef[0].key) {
        sortDef[0].order = sorters[0].order === "asc" ? "desc" : "asc";
      }

      setSorters(sortDef);
    };
  }

  const columns = [
    {
      key: "artist",
      title: "Artist",
      sort: [
        { key: "artist", order: "asc" },
        { key: "album", order: "asc" },
        { key: "number", order: "asc" }
      ]
    },
    {
      key: "number",
      title: "#",
      sort: [
        { key: "number", order: "asc" },
        { key: "artist", order: "asc" }
      ]
    },
    {
      key: "name",
      title: "Title",
      sort: [
        { key: "name", order: "asc" },
        { key: "artist", order: "asc" }
      ]
    },
    {
      key: "album",
      title: "Album",
      sort: [
        { key: "album", order: "asc" },
        { key: "number", order: "asc" }
      ]
    },
    {
      key: "releaseDate",
      title: "Release Date",
      sort: [
        { key: "releaseDate", order: "asc" },
        { key: "album", order: "asc" },
        { key: "number", order: "asc" }
      ]
    },
    {
      key: "addedAt",
      title: "Added At",
      sort: [
        { key: "addedAt", order: "asc" },
        { key: "album", order: "asc" },
        { key: "number", order: "asc" }
      ]
    },
    {
      key: "rating",
      title: "Rating",
      sort: [
        { key: "rating", order: "desc" },
        { key: "number", order: "asc" }
      ]
    }
  ];

  const header = columns.map(col => {
    let c = "sort";
    let symbol = "▲";
    if (col.key === sorters[0].key) {
      c = "sort-active";
      if (sorters[0].order === "desc") {
        symbol = "▼";
      }
    }

    return (
      <div
        className="lb-album-detail-table-cell"
        key={col.key}
        onClick={createSortHandler(col.sort)}
      >
        {col.title}
        <span className={c}>{symbol}</span>
      </div>
    );
  });

  return (
    <div className="col-sm lb-album-detail">
      <div className="lb-album-detail-table">
        <div className="lb-album-detail-table-header">
          <div className="lb-album-detail-table-row">{header}</div>
        </div>
        <div className="lb-album-detail-table-body">
          <AutoSizer>
            {({ height, width }) => (
              <List height={height} itemCount={tracks.length} itemSize={18} width={width}>
                {Row}
              </List>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, { updateTrackRating })(AlbumDetail);
