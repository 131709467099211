import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import { addTracks, removeRelease } from "./Database";

import "./Releases.css";
import { Release, Track } from "./library_api";
import { releasesAPI, spotifyAPI } from "./LibraryAPI";

interface State {
  releases: Release[];
}

function mapStateToProps(state: State) {
  const { releases } = state;
  return {
    releases: releases
  };
}

interface Props {
  releases: Release[];
  addTracks(tracks: Track[]): void;
  removeRelease(release: Release): void;
}

function Releases(props: Props) {
  function handleSpotifyClick(e: React.MouseEvent) {
    e.stopPropagation();
  }

  function importRelease(release: Release) {
    spotifyAPI.addAlbumSpotify({ albumID: release.spotifyAlbumID }).then(resp => {
      props.addTracks(resp.data.tracks);

      releasesAPI.deleteReleases({ releases: [release] }).then(() => {
        props.removeRelease(release);
      });
    });
  }

  function removeRelease(release: Release) {
    releasesAPI.deleteReleases({ releases: [release] }).then(() => {
      props.removeRelease(release);
    });
  }

  if (props.releases.length === 0) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm">
            <div className="lb-releases-all-checked">
              <div>
                <i className="bx bxs-check-square text-success"></i>
              </div>
              <div className="text-secondary">You have checked all new releases.</div>
              <div className="mt-2">
                <Link className="btn btn-primary btn-sm" to="/">
                  Back to the Library
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const cards = props.releases.map(release => {
    const releaseDate = moment(release.releaseDate).format("YYYY-MM-DD");
    return (
      <div className="col mb-4" key={release.id}>
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">
              {release.album}{" "}
              <a href={`spotify:album:${release["spotifyAlbumID"]}`} onClick={handleSpotifyClick}>
                <i className="bx bxl-spotify"></i>
              </a>
            </h4>
            <h5 className="card-subtitle mb-2 text-muted">
              {release.artist}{" "}
              <a href={`spotify:artist:${release["spotifyArtistID"]}`} onClick={handleSpotifyClick}>
                <i className="bx bxl-spotify"></i>
              </a>
            </h5>
            <p className="card-text">Release: {releaseDate}</p>
            <button
              className="btn btn-primary btn-sm card-link"
              onClick={() => importRelease(release)}
            >
              Import into Library
            </button>
            <button
              className="btn btn-danger btn-sm card-link"
              onClick={() => removeRelease(release)}
            >
              Remove from Releases
            </button>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="lb-releases-list">
      <div className="row row-cols-1 row-cols-lg-3">{cards}</div>
    </div>
  );
}

export default connect(mapStateToProps, { addTracks, removeRelease })(Releases);
