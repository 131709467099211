import { combineReducers, createStore } from "redux";
import { Release, Track } from "./library_api";

export function query(filters: Filter[], tracks: Track[]): Track[] {
  let result = JSON.parse(JSON.stringify(tracks));
  for (const f of filters) {
    result = result.filter((track: Track) => {
      switch (f.key) {
        case "album":
          return track.album === f.value;
        case "artist":
          return track.artist === f.value;
        case "tags":
          return track.tags.includes(f.value);
        default:
          return false;
      }
    });
  }

  return result;
}

export interface Filter {
  key: string;
  value: string;
}

export const FILTERS_SET = "FILTERS_SET";
export const FILTERS_SET_TO = "FILTERS_SET_TO";

export interface FilterSetAction {
  type: typeof FILTERS_SET;
  filters: Filter[];
}

export interface FiltersSetToAction {
  type: typeof FILTERS_SET_TO;
  filter: Filter;
  position: number;
}

export type FiltersActionTypes = FilterSetAction | FiltersSetToAction;

let initalFilters: Filter[] = [];
const storedFilters = localStorage.getItem("lb-filters");
if (storedFilters !== null) {
  initalFilters = JSON.parse(storedFilters);
}

function filters(state = initalFilters, action: FiltersActionTypes): Filter[] {
  switch (action.type) {
    case FILTERS_SET:
      return action.filters;
    case FILTERS_SET_TO:
      const newFilters = state.slice(0, action.position + 1);
      newFilters[action.position] = action.filter;
      return newFilters;
    default:
      return state;
  }
}

export function setFilters(filters: Filter[]): FiltersActionTypes {
  return {
    type: FILTERS_SET,
    filters: filters
  };
}

export function setFiltersTo(position: number, filter: Filter): FiltersActionTypes {
  return {
    type: FILTERS_SET_TO,
    position: position,
    filter: filter
  };
}

export const RELEASES_RELEASE_REMOVE = "RELEASES_RELEASE_REMOVE";
export const RELEASES_ADD = "RELEASES_ADD";

export interface ReleasesReleaseRemoveAction {
  type: typeof RELEASES_RELEASE_REMOVE;
  release: Release;
}

export interface ReleasesAddAction {
  type: typeof RELEASES_ADD;
  releases: Release[];
}

export type ReleaseActionTypes = ReleasesReleaseRemoveAction | ReleasesAddAction;

const initialReleasesState: Release[] = [];

function releases(state = initialReleasesState, action: ReleaseActionTypes): Release[] {
  switch (action.type) {
    case RELEASES_ADD:
      return [...state, ...action.releases];
    case RELEASES_RELEASE_REMOVE:
      return state.filter(release => {
        return release.id !== action.release.id;
      });
    default:
      return state;
  }
}

export function addReleases(releases: Release[]): ReleaseActionTypes {
  return {
    type: RELEASES_ADD,
    releases: releases
  };
}

export function removeRelease(release: Release): ReleaseActionTypes {
  return {
    type: RELEASES_RELEASE_REMOVE,
    release: release
  };
}

export const TRACKS_ADD = "TRACKS_ADD";
export const TRACKS_DELETE = "TRACKS_DELETE";
export const TRACKS_UPDATE_RATING = "TRACKS_UPDATE_RATING";
export const TRACKS_UPDATE_TAGS = "TRACKS_UPDATE_TAGS";

export interface TracksUpdateAction {
  type: typeof TRACKS_ADD;
  tracks: Track[];
}

export interface TracksDeleteAction {
  type: typeof TRACKS_DELETE;
  ids: number[];
}

export interface TracksUpdateRatingAction {
  type: typeof TRACKS_UPDATE_RATING;
  id: number;
  rating: number;
}

export interface TracksUpdateTagsAction {
  type: typeof TRACKS_UPDATE_TAGS;
  id: number;
  tags: string[];
}

export type TracksActionTypes =
  | TracksUpdateAction
  | TracksDeleteAction
  | TracksUpdateRatingAction
  | TracksUpdateTagsAction;

const initialTracksState: Track[] = [];

function tracks(state = initialTracksState, action: TracksActionTypes): Track[] {
  switch (action.type) {
    case TRACKS_ADD:
      return [...state, ...action.tracks];
    case TRACKS_DELETE:
      return state.filter(track => {
        return action.ids.includes(track.id) ? false : true;
      });

    case TRACKS_UPDATE_RATING:
      return state.map(track => {
        if (track.id === action.id) {
          const uTrack = Object.assign({}, track);
          uTrack.rating = action.rating;
          return uTrack;
        }

        return track;
      });

    case TRACKS_UPDATE_TAGS:
      return state.map(track => {
        if (track.id === action.id) {
          const uTrack = Object.assign({}, track);
          uTrack.tags = action.tags;
          return uTrack;
        }

        return track;
      });

    default:
      return state;
  }
}

export function addTracks(tracks: Track[]): TracksActionTypes {
  return {
    type: TRACKS_ADD,
    tracks: tracks
  };
}

export function deleteTracks(ids: number[]): TracksActionTypes {
  return {
    type: TRACKS_DELETE,
    ids: ids
  };
}

export function updateTrackRating(id: number, rating: number): TracksActionTypes {
  return {
    type: TRACKS_UPDATE_RATING,
    id: id,
    rating: rating
  };
}

export function updateTrackTags(id: number, tags: string[]): TracksActionTypes {
  return {
    type: TRACKS_UPDATE_TAGS,
    id: id,
    tags: tags
  };
}

const reducers = combineReducers({
  filters,
  releases,
  tracks
});

export const store = createStore(reducers);

store.subscribe(function() {
  const filters = store.getState().filters;
  localStorage.setItem("lb-filters", JSON.stringify(filters));
});
