/* tslint:disable */
/* eslint-disable */
/**
 * library API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface AlbumSpotify
 */
export interface AlbumSpotify {
  /**
   *
   * @type {string}
   * @memberof AlbumSpotify
   */
  albumID: string;
}
/**
 *
 * @export
 * @interface Release
 */
export interface Release {
  /**
   *
   * @type {string}
   * @memberof Release
   */
  artist: string;
  /**
   *
   * @type {string}
   * @memberof Release
   */
  album: string;
  /**
   *
   * @type {number}
   * @memberof Release
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Release
   */
  spotifyAlbumID: string;
  /**
   *
   * @type {string}
   * @memberof Release
   */
  spotifyArtistID: string;
  /**
   *
   * @type {string}
   * @memberof Release
   */
  releaseDate: string;
}
/**
 *
 * @export
 * @interface ReleaseList
 */
export interface ReleaseList {
  /**
   *
   * @type {Array<Release>}
   * @memberof ReleaseList
   */
  releases: Array<Release>;
}
/**
 *
 * @export
 * @interface Track
 */
export interface Track {
  /**
   *
   * @type {string}
   * @memberof Track
   */
  artist: string;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  album: string;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  genre: string;
  /**
   *
   * @type {number}
   * @memberof Track
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Track
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  spotifyAlbumID: string;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  spotifyTrackID: string;
  /**
   *
   * @type {number}
   * @memberof Track
   */
  rating: number;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  releaseDate: string;
  /**
   *
   * @type {string}
   * @memberof Track
   */
  addedAt: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Track
   */
  tags: Array<string>;
}
/**
 *
 * @export
 * @interface TrackList
 */
export interface TrackList {
  /**
   *
   * @type {Array<Track>}
   * @memberof TrackList
   */
  tracks: Array<Track>;
}

/**
 * ReleasesApi - axios parameter creator
 * @export
 */
export const ReleasesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete releases of artists in the library of the user.
     * @param {ReleaseList} releaseList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReleases: async (releaseList: ReleaseList, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'releaseList' is not null or undefined
      if (releaseList === null || releaseList === undefined) {
        throw new RequiredError(
          "releaseList",
          "Required parameter releaseList was null or undefined when calling deleteReleases."
        );
      }
      const localVarPath = `/api/releases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      const needsSerialization =
        typeof releaseList !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(releaseList !== undefined ? releaseList : {})
        : releaseList || "";

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary List new releases of artists in the library of the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listReleases: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/releases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ReleasesApi - functional programming interface
 * @export
 */
export const ReleasesApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete releases of artists in the library of the user.
     * @param {ReleaseList} releaseList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteReleases(
      releaseList: ReleaseList,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await ReleasesApiAxiosParamCreator(configuration).deleteReleases(
        releaseList,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary List new releases of artists in the library of the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listReleases(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseList>> {
      const localVarAxiosArgs = await ReleasesApiAxiosParamCreator(configuration).listReleases(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * ReleasesApi - factory interface
 * @export
 */
export const ReleasesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Delete releases of artists in the library of the user.
     * @param {ReleaseList} releaseList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReleases(releaseList: ReleaseList, options?: any): AxiosPromise<void> {
      return ReleasesApiFp(configuration)
        .deleteReleases(releaseList, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary List new releases of artists in the library of the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listReleases(options?: any): AxiosPromise<ReleaseList> {
      return ReleasesApiFp(configuration)
        .listReleases(options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * ReleasesApi - object-oriented interface
 * @export
 * @class ReleasesApi
 * @extends {BaseAPI}
 */
export class ReleasesApi extends BaseAPI {
  /**
   *
   * @summary Delete releases of artists in the library of the user.
   * @param {ReleaseList} releaseList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReleasesApi
   */
  public deleteReleases(releaseList: ReleaseList, options?: any) {
    return ReleasesApiFp(this.configuration)
      .deleteReleases(releaseList, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List new releases of artists in the library of the user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReleasesApi
   */
  public listReleases(options?: any) {
    return ReleasesApiFp(this.configuration)
      .listReleases(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SpotifyApi - axios parameter creator
 * @export
 */
export const SpotifyApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add an album to your collection by its URI.
     * @param {AlbumSpotify} albumSpotify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAlbumSpotify: async (
      albumSpotify: AlbumSpotify,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'albumSpotify' is not null or undefined
      if (albumSpotify === null || albumSpotify === undefined) {
        throw new RequiredError(
          "albumSpotify",
          "Required parameter albumSpotify was null or undefined when calling addAlbumSpotify."
        );
      }
      const localVarPath = `/api/spotify/albums`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      const needsSerialization =
        typeof albumSpotify !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(albumSpotify !== undefined ? albumSpotify : {})
        : albumSpotify || "";

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Import saved albums from Spotify.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importSpotify: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/spotify/sync`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SpotifyApi - functional programming interface
 * @export
 */
export const SpotifyApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add an album to your collection by its URI.
     * @param {AlbumSpotify} albumSpotify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAlbumSpotify(
      albumSpotify: AlbumSpotify,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackList>> {
      const localVarAxiosArgs = await SpotifyApiAxiosParamCreator(configuration).addAlbumSpotify(
        albumSpotify,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Import saved albums from Spotify.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importSpotify(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackList>> {
      const localVarAxiosArgs = await SpotifyApiAxiosParamCreator(configuration).importSpotify(
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * SpotifyApi - factory interface
 * @export
 */
export const SpotifyApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Add an album to your collection by its URI.
     * @param {AlbumSpotify} albumSpotify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAlbumSpotify(albumSpotify: AlbumSpotify, options?: any): AxiosPromise<TrackList> {
      return SpotifyApiFp(configuration)
        .addAlbumSpotify(albumSpotify, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Import saved albums from Spotify.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importSpotify(options?: any): AxiosPromise<TrackList> {
      return SpotifyApiFp(configuration)
        .importSpotify(options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * SpotifyApi - object-oriented interface
 * @export
 * @class SpotifyApi
 * @extends {BaseAPI}
 */
export class SpotifyApi extends BaseAPI {
  /**
   *
   * @summary Add an album to your collection by its URI.
   * @param {AlbumSpotify} albumSpotify
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotifyApi
   */
  public addAlbumSpotify(albumSpotify: AlbumSpotify, options?: any) {
    return SpotifyApiFp(this.configuration)
      .addAlbumSpotify(albumSpotify, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import saved albums from Spotify.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpotifyApi
   */
  public importSpotify(options?: any) {
    return SpotifyApiFp(this.configuration)
      .importSpotify(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TracksApi - axios parameter creator
 * @export
 */
export const TracksApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete tracks.
     * @param {TrackList} trackList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTracks: async (trackList: TrackList, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'trackList' is not null or undefined
      if (trackList === null || trackList === undefined) {
        throw new RequiredError(
          "trackList",
          "Required parameter trackList was null or undefined when calling deleteTracks."
        );
      }
      const localVarPath = `/api/tracks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      const needsSerialization =
        typeof trackList !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(trackList !== undefined ? trackList : {})
        : trackList || "";

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Load all tracks of the logged in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listTracks: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/tracks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Update tracks.
     * @param {TrackList} trackList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTracks: async (trackList: TrackList, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'trackList' is not null or undefined
      if (trackList === null || trackList === undefined) {
        throw new RequiredError(
          "trackList",
          "Required parameter trackList was null or undefined when calling updateTracks."
        );
      }
      const localVarPath = `/api/tracks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication BearerAuth required
      // http bearer authentication required
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      const needsSerialization =
        typeof trackList !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(trackList !== undefined ? trackList : {})
        : trackList || "";

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * TracksApi - functional programming interface
 * @export
 */
export const TracksApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete tracks.
     * @param {TrackList} trackList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTracks(
      trackList: TrackList,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await TracksApiAxiosParamCreator(configuration).deleteTracks(
        trackList,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Load all tracks of the logged in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listTracks(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackList>> {
      const localVarAxiosArgs = await TracksApiAxiosParamCreator(configuration).listTracks(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update tracks.
     * @param {TrackList} trackList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTracks(
      trackList: TrackList,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackList>> {
      const localVarAxiosArgs = await TracksApiAxiosParamCreator(configuration).updateTracks(
        trackList,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * TracksApi - factory interface
 * @export
 */
export const TracksApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Delete tracks.
     * @param {TrackList} trackList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTracks(trackList: TrackList, options?: any): AxiosPromise<void> {
      return TracksApiFp(configuration)
        .deleteTracks(trackList, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Load all tracks of the logged in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listTracks(options?: any): AxiosPromise<TrackList> {
      return TracksApiFp(configuration)
        .listTracks(options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update tracks.
     * @param {TrackList} trackList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTracks(trackList: TrackList, options?: any): AxiosPromise<TrackList> {
      return TracksApiFp(configuration)
        .updateTracks(trackList, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * TracksApi - object-oriented interface
 * @export
 * @class TracksApi
 * @extends {BaseAPI}
 */
export class TracksApi extends BaseAPI {
  /**
   *
   * @summary Delete tracks.
   * @param {TrackList} trackList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TracksApi
   */
  public deleteTracks(trackList: TrackList, options?: any) {
    return TracksApiFp(this.configuration)
      .deleteTracks(trackList, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Load all tracks of the logged in user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TracksApi
   */
  public listTracks(options?: any) {
    return TracksApiFp(this.configuration)
      .listTracks(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update tracks.
   * @param {TrackList} trackList
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TracksApi
   */
  public updateTracks(trackList: TrackList, options?: any) {
    return TracksApiFp(this.configuration)
      .updateTracks(trackList, options)
      .then(request => request(this.axios, this.basePath));
  }
}
