import Handlebars from "handlebars";

Handlebars.registerHelper("padZero", function(number, p) {
  return String(number).padStart(p, "0");
});

const templateCache: Record<string, HandlebarsTemplateDelegate> = {};

export function getTemplate(tpl: string): HandlebarsTemplateDelegate {
  if (templateCache[tpl]) {
    return templateCache[tpl];
  }

  const hb = Handlebars.compile(tpl, { noEscape: true });
  templateCache[tpl] = hb;
  return hb;
}
