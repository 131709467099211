import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { addTracks, Filter, setFilters } from "./Database";
import { Track } from "./library_api";
import { spotifyAPI } from "./LibraryAPI";

function parse(input: string): string {
  const uri = parseAlbumURI(input);
  if (uri !== "") {
    return uri;
  }

  const link = parseAlbumLink(input);
  if (link !== "") {
    return link;
  }

  return "";
}

function parseAlbumLink(input: string): string {
  let url;
  try {
    url = new URL(input);
  } catch (error) {
    return "";
  }

  if (url.hostname !== "open.spotify.com") {
    return "";
  }

  if (!url.pathname.startsWith("/album/")) {
    return "";
  }

  const parts = url.pathname.split("/");
  if (parts.length !== 3) {
    return "";
  }

  return parts[2];
}

function parseAlbumURI(input: string): string {
  if (!input.startsWith("spotify:album:")) {
    return "";
  }

  return input.replace("spotify:album:", "");
}

interface Props {
  addTracks(tracks: Track[]): void;
  setFilters(filters: Filter[]): void;
}

function SpotifyAlbumIDForm(props: Props) {
  const [input, setInput] = useState("");
  const history = useHistory();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInput(e.target.value);
  }

  function handleSubmit(e: React.MouseEvent) {
    e.preventDefault();
    const spotifyAlbumID = parse(input);
    if (spotifyAlbumID === "") {
      return;
    }

    spotifyAPI
      .addAlbumSpotify({ albumID: spotifyAlbumID })
      .then(resp => {
        const tl = resp.data;
        if (tl.tracks.length > 0) {
          props.addTracks(tl.tracks);
          props.setFilters([
            { key: "tags", value: tl.tracks[0].tags[0] },
            { key: "artist", value: tl.tracks[0].artist },
            { key: "album", value: tl.tracks[0].album }
          ]);
        }

        history.push("/");
      })
      .catch(e => {
        console.log(e);
      });
  }

  return (
    <form>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          placeholder="spotify:album:<ID>"
          id="spotifyAlbumImport"
          aria-label="Album Import"
          value={input}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <button className="btn btn-primary" type="submit" onClick={handleSubmit}>
          Add Album
        </button>
        <Link className="btn btn-secondary ml-2" to="/">
          Back
        </Link>
        <small id="passwordHelpBlock" className="form-text text-muted">
          Add an album to your library by its Spotify ID.
        </small>
      </div>
    </form>
  );
}

export default connect(null, { addTracks, setFilters })(SpotifyAlbumIDForm);
